import {
  Tab,
  TabsComponent as Tabs,
  TabsProps,
  IconPosition,
} from '@vaa-component-lib/component.molecule.tabs';
import getIcon from '../icons';
import { renderComponent } from 'src/utils/component-utils/utils';
import styles from './tabs-component.module.less';

interface TabsComponentProps {
  id: string;
  items: any[];
  activeItem: string;
  itemsOrder: string[];
  accessibilityLabel: string;
  lozenge?: boolean;
}

const handleTabItemProps = (
  id: number,
  tab: any,
  activeItem: string
): Tab | null => {
  if (!tab) {
    return null;
  }
  const Icon = tab.iconSvg && getIcon(tab.iconSvg);

  return {
    ...tab,
    id: id,
    label: tab['cq:panelTitle'],
    content: renderComponent('tab-item', id, tab),
    disabled: tab.isDisabled || false,
    iconChild: Icon && <Icon />,
    iconPosition:
      tab.iconPosition === 'right' ? IconPosition.Right : IconPosition.Left,
    selected: activeItem === tab.id,
  };
};

export default function TabsComponent({ id, items, activeItem, itemsOrder, accessibilityLabel, lozenge }: TabsComponentProps) {
  if (!items || !items[0]) {
    return null;
  }
  let activeTabId = activeItem;
  const orderedTabs = items.sort( (a, b) => {
    return itemsOrder.indexOf(a.id) - itemsOrder.indexOf(b.id);
  });

  const activeItemIsDisabled = orderedTabs.find(
    (tab: any) => tab.id === activeItem
  )?.isDisabled;

  if (activeItemIsDisabled) {
    const firstEnabledItem = orderedTabs.find((tab: any) => !tab.isDisabled);
    activeTabId = firstEnabledItem?.id || '';
  }

  const updatedTabItems = orderedTabs.map((tab: any, tabIndex: number) => 
    handleTabItemProps(tabIndex, tab, activeTabId))
    .filter((tab: Tab | null) => tab !== null);

  const tabsPropsUpdated: TabsProps = {
    lozenge: lozenge,
    tabId: id,
    showIcons: true,
    accessibilityLabel: accessibilityLabel,
    tabs: updatedTabItems
  };

  return (
    <div className={styles.tabs}>
      <Tabs {...tabsPropsUpdated} />
    </div>
  );
}
